<template>
  <div id="rebateTransfer">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <div class="form-container">
          <el-form :model="transferForm" :rules="rules" label-position="top" ref="transferForm">
            <div class="main">
              <div class="left">
                <el-steps direction="vertical">
                  <el-step icon="red"></el-step>
                  <el-step icon="blue"></el-step>
                  <el-step icon="red"></el-step>
                </el-steps>
              </div>
              <div class="right">
                <h3>{{ $t('transfer.REBATE_TRANSFER_REQUEST') }}</h3>
                <div class="form-list-inner">
                  <ul class="clearfix">
                    <li>
                      <p>{{ $t('common.keys.FROM') }}</p>
                      <el-form-item :label="$t('common.keys.rebateAccount')" prop="rebateAccount">
                        <el-select
                          v-model="transferForm.rebateAccount"
                          @change="rebateAccountChange"
                          data-testid="rebateAccount"
                        >
                          <el-option
                            v-for="item in rebates"
                            :key="item.login"
                            :label="`${item.login} - ${item.balance}  ${item.currency}`"
                            :value="item.login"
                            :data-testid="item.login"
                            :disabled="item.balance < 0"
                          >
                          </el-option>
                        </el-select>
                        <img class="link" src="@/assets/images/rebateTransfer/link.png" alt="" />
                      </el-form-item>
                    </li>
                    <li>
                      <el-form-item :label="$t('common.keys.amount')" prop="amount">
                        <numeric-input
                          ref="numberInput"
                          v-model="transferForm.amount"
                          :showCurrency="true"
                          :showCurrencySymbol="selectedRebateAccountCurrency | currencySymbol"
                          :currency="selectedRebateAccountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <div class="form-list-inner">
                  <ul class="clearfix">
                    <li>
                      <p>{{ $t('common.keys.TO') }}</p>
                      <el-form-item :label="$t('transfer.account')" prop="tradingAccount">
                        <el-select v-model="transferForm.tradingAccount" data-testid="tradingAccount">
                          <el-option
                            v-for="item in tradingAccountList"
                            :key="item.login"
                            :label="item.login | accountListFormatter(item, transferTranslationObj)"
                            :value="item.login"
                            :data-testid="item.login"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </li>
                    <li v-if="this.currentCurrency && this.selectedRebateAccountCurrency !== this.currentCurrency">
                      <el-form-item :label="$t('common.keys.amountIn') + ` ${currentCurrency}`" prop="convertedAmt">
                        <el-input
                          v-model="amountTo"
                          id="convertedAmt"
                          auto-complete="new-password"
                          data-testid="convertedAmt"
                          disabled
                        />
                      </el-form-item>
                    </li>
                  </ul>
                </div>

                <el-form-item>
                  <el-button class="btn-red" @click="submitForm('transferForm')" data-testid="submitTransferForm">
                    {{ $t('common.keys.SUBMIT') }}
                  </el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiToApplyTransferView, apiApplyTransfer, apiGetTransferableCurrency, apiGetTransferToRate } from '@/resource';
import NumericInput from '@/components/NumericInput';
import floatObj from '@/util/floatObj';
import mixin from '@/mixins';
import blackList from '@/mixins/blackList';

export default {
  components: { NumericInput },
  mixins: [mixin, blackList],
  data() {
    return {
      rate: 0,
      amountTo: '',
      currencyDataList: [],
      currentCurrency: '',
      seconedBalance: '',
      accountList: [],
      tradingAccountList: [],
      rebates: [],
      transferForm: {
        rebateAccount: '',
        amount: '',
        tradingAccount: ''
      },
      selectedRebateAccountCurrency: '',
      availableBalance: '0',
      transferTranslationObj: {
        1: this.$t('transfer.tradingAccount'),
        3: this.$t('transfer.rebateAccount')
      },
      rules: {
        rebateAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('transfer.PleaseEnterTransferInformation'),

            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value == 0) {
                callback(new Error(this.$t('withdraw.Itcannotbe0')));
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('transfer.transferAmountIsGreaterThanAvailableBalance')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        tradingAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ]
      },
      userId: this.$store.state.common.CUID,
      prevRoute: null
    };
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  watch: {
    selectedRebateAccountCurrency() {
      if (this.transferForm.amount !== 0) this.$refs.transferForm.validateField('amount');
    },
    'transferForm.rebateAccount'(value) {
      let list = this.rebates.concat(this.accountList);
      this.transferForm.tradingAccount = '';
      this.transferForm.rebateAccount = value;
      const [arr] = list.filter(item => {
        return item.login === value;
      });
      this.selectedRebateAccountCurrency = arr.currency;
      this.getTransferableCurrency(this.selectedRebateAccountCurrency);
    },
    'transferForm.tradingAccount'(value) {
      this.transferForm.tradingAccount = value;
      const [arr] = this.tradingAccountList.filter(item => {
        return item.login === value;
      });
      this.currentCurrency = arr.currency;
      this.getTransferToRate();
    },
    'transferForm.amount'(value) {
      this.amountTo = this.$options.filters.numberWithCommas(floatObj.multiply(value, this.rate));
    }
  },
  filters: {
    accountListFormatter: function(value, item, transferTranslationObj) {
      if (value === undefined || value === null) {
        return '';
      } else {
        const currency = item.currency || ' ';
        const accountType =
          item.accountDealType === 1 || item.accountDealType === 3 ? transferTranslationObj[item.accountDealType] : '';
        value = item.login + ' - ' + currency + (accountType ? ' (' + accountType + ')' : '');
      }
      return value;
    }
  },
  methods: {
    getTransferToRate() {
      let data = {
        formCurrency: this.selectedRebateAccountCurrency,
        toCurrency: this.currentCurrency
      };
      apiGetTransferToRate(data)
        .then(res => {
          this.rate = res.data.data;
          this.amountTo = this.$options.filters.numberWithCommas(
            floatObj.multiply(this.transferForm.amount, this.rate)
          );
        })
        .catch(err => {
          console.log(err);
        });
    },
    accountToChange(val) {
      const [availableBalance] = this.tradingAccountList.filter(item => {
        return item.login === val;
      });
      if (availableBalance == undefined) {
        return;
      }
      this.seconedBalance = availableBalance.balance;
      this.currentCurrency = availableBalance.currency;
    },
    getTransferableCurrency(currency) {
      this.currencyDataList = [];
      apiGetTransferableCurrency(currency)
        .then(res => {
          if (res.data.code === 0) {
            this.currencyDataList = res.data.data;
            this.rebateAccountChange(this.transferForm.rebateAccount);
          }
        })
        .catch(err => {
          this.$message({ message: 'Fetch trading accounts failed.', type: 'error' });
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return false;
        else if (!this.checkRebateTransferBlacklist()) {
          this.transferErrMsg();
        } else this.applyTransfer();
      });
    },
    getAccountInfo() {
      apiToApplyTransferView({
        qUserId: this.userId
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          this.accountList = respData.accountList;
          this.rebates = respData.rebates;
          this.rebates.forEach(element => {
            element.balance = element.balance.toFixed(2);
          });
          // 默认选中首页选中的账号
          if (this.rebates && this.rebates.length > 0) {
            const firstAccountIndex = this.rebates.findIndex(account => Number(account.balance) >= 0);
            if (firstAccountIndex !== -1) {
              this.transferForm.rebateAccount = this.rebates[firstAccountIndex].login;
              this.selectedRebateAccountCurrency = this.rebates[firstAccountIndex].currency;
            }
          }
        }
      });
    },
    // 佣金账号改变
    rebateAccountChange(val) {
      const [availableBalance] = this.rebates.filter(item => {
        return item.login === val;
      });
      if (availableBalance == undefined) {
        return;
      }
      this.availableBalance = availableBalance.balance;
      this.selectedRebateAccountCurrency = availableBalance.currency;
      let selectionList = this.rebates.concat(this.accountList).filter(item => {
        return item.login !== val;
      });
      this.tradingAccountList = selectionList.filter(a => {
        return this.currencyDataList.includes(a.currency);
      });
      this.transferForm.tradingAccount = this.tradingAccountList.length > 0 ? this.tradingAccountList[0].login : null;
      this.currentCurrency = this.tradingAccountList.length > 0 ? this.tradingAccountList[0].currency : null;
    },
    applyTransfer() {
      apiApplyTransfer(
        {
          qUserId: this.userId,
          fromAccount: this.transferForm.rebateAccount,
          toAccount: this.transferForm.tradingAccount,
          amount: this.transferForm.amount,
          transferType:
            this.tradingAccountList.find(item => item.login === this.transferForm.tradingAccount).accountDealType === 1
              ? 3
              : 4
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 0) {
          this.$message.success(this.$t('transfer.yourRebateTransferHasBeenProcessed'));
          this.bus.$emit('updateTransferHistory', true);
          this.$nextTick(function() {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        }
        if (resp.data.code === 551) window.location.reload();
      });
    },
    async checkRebateTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true;
      else
        await this.getRebateTransferBlacklist().then(resp => {
          return resp;
        });
    }
  },
  mounted() {
    this.getAccountInfo();
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/rebateTransfer.scss';
.el-select-dropdown .el-select-dropdown__wrap .el-select-dropdown__list .el-select-dropdown__item.is-disabled {
  color: #c6c6c6;
}
</style>
